import _extends from 'babel-runtime/helpers/extends';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _createClass from 'babel-runtime/helpers/createClass';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
import React from 'react';
import ReactDOM from 'react-dom';
import Dialog from './Dialog';
function noop() {}
var IS_REACT_16 = !!ReactDOM.createPortal;
var CAN_USE_DOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

var DialogWrap = function (_React$Component) {
    _inherits(DialogWrap, _React$Component);

    function DialogWrap() {
        _classCallCheck(this, DialogWrap);

        var _this = _possibleConstructorReturn(this, (DialogWrap.__proto__ || Object.getPrototypeOf(DialogWrap)).apply(this, arguments));

        _this.saveRef = function (node) {
            if (IS_REACT_16) {
                _this._component = node;
            }
        };
        _this.getComponent = function (visible) {
            var props = _extends({}, _this.props);
            ['visible', 'onAnimateLeave'].forEach(function (key) {
                if (props.hasOwnProperty(key)) {
                    delete props[key];
                }
            });
            return React.createElement(Dialog, _extends({}, props, { visible: visible, onAnimateLeave: _this.removeContainer, ref: _this.saveRef }));
        };
        _this.removeContainer = function () {
            if (_this.container) {
                if (!IS_REACT_16) {
                    ReactDOM.unmountComponentAtNode(_this.container);
                }
                _this.container.parentNode.removeChild(_this.container);
                _this.container = null;
            }
        };
        _this.getContainer = function () {
            if (!_this.container) {
                var container = document.createElement('div');
                var containerId = _this.props.prefixCls + '-container-' + new Date().getTime();
                container.setAttribute('id', containerId);
                document.body.appendChild(container);
                _this.container = container;
            }
            return _this.container;
        };
        return _this;
    }

    _createClass(DialogWrap, [{
        key: 'componentDidMount',
        value: function componentDidMount() {
            if (this.props.visible) {
                this.componentDidUpdate();
            }
        }
    }, {
        key: 'shouldComponentUpdate',
        value: function shouldComponentUpdate(_ref) {
            var visible = _ref.visible;

            return !!(this.props.visible || visible);
        }
    }, {
        key: 'componentWillUnmount',
        value: function componentWillUnmount() {
            if (this.props.visible) {
                if (!IS_REACT_16) {
                    this.renderDialog(false);
                } else {
                    // TODO for react@16 createPortal animation
                    this.removeContainer();
                }
            } else {
                this.removeContainer();
            }
        }
    }, {
        key: 'componentDidUpdate',
        value: function componentDidUpdate() {
            if (!IS_REACT_16) {
                this.renderDialog(this.props.visible);
            }
        }
    }, {
        key: 'renderDialog',
        value: function renderDialog(visible) {
            ReactDOM.unstable_renderSubtreeIntoContainer(this, this.getComponent(visible), this.getContainer());
        }
    }, {
        key: 'render',
        value: function render() {
            if (!CAN_USE_DOM) {
                return null;
            }
            var visible = this.props.visible;

            if (IS_REACT_16 && (visible || this._component)) {
                return ReactDOM.createPortal(this.getComponent(visible), this.getContainer());
            }
            return null;
        }
    }]);

    return DialogWrap;
}(React.Component);

export default DialogWrap;

DialogWrap.defaultProps = {
    visible: false,
    prefixCls: 'rmc-dialog',
    onClose: noop
};