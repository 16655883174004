import { useRef } from 'react';

function usePersistFn(fn) {
  var fnRef = useRef(fn);
  fnRef.current = fn;
  var persistFn = useRef();

  if (!persistFn.current) {
    persistFn.current = function () {
      var args = [];

      for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
      }

      return fnRef.current.apply(this, args);
    };
  }

  return persistFn.current;
}

export default usePersistFn;