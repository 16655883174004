import _extends from 'babel-runtime/helpers/extends';
import _defineProperty from 'babel-runtime/helpers/defineProperty';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _createClass from 'babel-runtime/helpers/createClass';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
var __rest = this && this.__rest || function (s, e) {
    var t = {};
    for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    }if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
        if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
    }return t;
};
import classnames from 'classnames';
import * as React from 'react';
import TouchFeedback from 'rmc-feedback';
import { IS_IOS } from '../_util/exenv';
/**
 * determines whether an array includes a certain value among its entries, returning true or false as appropriate.
 * @param {array} arr The array to search in
 * @param {any} item  The value to search for
 */
function includes(arr, item) {
    if (!arr || !arr.length || !item) {
        return false;
    }
    for (var i = 0, len = arr.length; i < len; i++) {
        if (arr[i] === item) {
            return true;
        }
    }
    return false;
}
export var KeyboardItem = function (_React$Component) {
    _inherits(KeyboardItem, _React$Component);

    function KeyboardItem() {
        _classCallCheck(this, KeyboardItem);

        return _possibleConstructorReturn(this, (KeyboardItem.__proto__ || Object.getPrototypeOf(KeyboardItem)).apply(this, arguments));
    }

    _createClass(KeyboardItem, [{
        key: 'render',
        value: function render() {
            var _a = this.props,
                prefixCls = _a.prefixCls,
                _onClick = _a.onClick,
                className = _a.className,
                disabled = _a.disabled,
                children = _a.children,
                tdRef = _a.tdRef,
                label = _a.label,
                iconOnly = _a.iconOnly,
                restProps = __rest(_a, ["prefixCls", "onClick", "className", "disabled", "children", "tdRef", "label", "iconOnly"]);
            var value = children;
            if (className === 'keyboard-delete') {
                value = 'delete';
            } else if (className === 'keyboard-hide') {
                value = 'hide';
            } else if (className === 'keyboard-confirm') {
                value = 'confirm';
            }
            var extraCls = _defineProperty({}, prefixCls + '-item-disabled', disabled);
            var wrapCls = classnames(prefixCls + '-item', className, extraCls);
            return React.createElement(
                TouchFeedback,
                { disabled: disabled, activeClassName: prefixCls + '-item-active' },
                React.createElement(
                    'td',
                    _extends({ ref: tdRef
                        // tslint:disable-next-line:jsx-no-multiline-js
                        , onClick: function onClick(e) {
                            _onClick(e, value);
                        }, className: wrapCls }, restProps),
                    children,
                    iconOnly && React.createElement(
                        'i',
                        { className: 'sr-only' },
                        label
                    )
                )
            );
        }
    }]);

    return KeyboardItem;
}(React.Component);
KeyboardItem.defaultProps = {
    prefixCls: 'am-number-keyboard',
    onClick: function onClick() {},
    disabled: false
};

var CustomKeyboard = function (_React$Component2) {
    _inherits(CustomKeyboard, _React$Component2);

    function CustomKeyboard() {
        _classCallCheck(this, CustomKeyboard);

        var _this2 = _possibleConstructorReturn(this, (CustomKeyboard.__proto__ || Object.getPrototypeOf(CustomKeyboard)).apply(this, arguments));

        _this2.onKeyboardClick = function (e) {
            var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

            e.nativeEvent.stopImmediatePropagation();
            if (_this2.props.disabledKeys && includes(_this2.props.disabledKeys, value)) {
                return null;
            }
            if (value === 'confirm' && _this2.confirmDisabled) {
                return null;
            } else {
                if (_this2.linkedInput) {
                    _this2.linkedInput.onKeyboardClick(value);
                }
            }
        };
        _this2.renderKeyboardItem = function (item, index) {
            var disabled = false;
            if (_this2.props.disabledKeys && includes(_this2.props.disabledKeys, item)) {
                disabled = true;
            }
            return React.createElement(
                KeyboardItem,
                { onClick: _this2.onKeyboardClick, key: 'item-' + item + '-' + index, disabled: disabled },
                item
            );
        };
        return _this2;
    }

    _createClass(CustomKeyboard, [{
        key: 'render',
        value: function render() {
            var _this3 = this;

            var _props = this.props,
                prefixCls = _props.prefixCls,
                confirmLabel = _props.confirmLabel,
                backspaceLabel = _props.backspaceLabel,
                cancelKeyboardLabel = _props.cancelKeyboardLabel,
                wrapProps = _props.wrapProps,
                header = _props.header;

            var wrapperCls = classnames(prefixCls + '-wrapper', prefixCls + '-wrapper-hide');
            return React.createElement(
                'div',
                _extends({ className: wrapperCls, ref: function ref(el) {
                        return _this3.antmKeyboard = el;
                    } }, wrapProps),
                header && React.cloneElement(header, { onClick: this.onKeyboardClick }),
                React.createElement(
                    'table',
                    null,
                    React.createElement(
                        'tbody',
                        null,
                        React.createElement(
                            'tr',
                            null,
                            ['1', '2', '3'].map(function (item, index) {
                                return (
                                    // tslint:disable-next-line:jsx-no-multiline-js
                                    _this3.renderKeyboardItem(item, index)
                                );
                            }),
                            React.createElement(KeyboardItem, _extends({ className: 'keyboard-delete', rowSpan: 2, onClick: this.onKeyboardClick }, this.getAriaAttr(backspaceLabel)))
                        ),
                        React.createElement(
                            'tr',
                            null,
                            ['4', '5', '6'].map(function (item, index) {
                                return (
                                    // tslint:disable-next-line:jsx-no-multiline-js
                                    _this3.renderKeyboardItem(item, index)
                                );
                            })
                        ),
                        React.createElement(
                            'tr',
                            null,
                            ['7', '8', '9'].map(function (item, index) {
                                return (
                                    // tslint:disable-next-line:jsx-no-multiline-js
                                    _this3.renderKeyboardItem(item, index)
                                );
                            }),
                            React.createElement(
                                KeyboardItem,
                                { className: 'keyboard-confirm', rowSpan: 2, onClick: this.onKeyboardClick, tdRef: function tdRef(el) {
                                        return _this3.confirmKeyboardItem = el;
                                    } },
                                confirmLabel
                            )
                        ),
                        React.createElement(
                            'tr',
                            null,
                            ['.', '0'].map(function (item, index) {
                                return (
                                    // tslint:disable-next-line:jsx-no-multiline-js
                                    _this3.renderKeyboardItem(item, index)
                                );
                            }),
                            React.createElement(KeyboardItem, _extends({ className: 'keyboard-hide', onClick: this.onKeyboardClick }, this.getAriaAttr(cancelKeyboardLabel)))
                        )
                    )
                )
            );
        }
    }, {
        key: 'getAriaAttr',
        value: function getAriaAttr(label) {
            if (IS_IOS) {
                return { label: label, iconOnly: true };
            } else {
                return { role: 'button', 'aria-label': label };
            }
        }
    }]);

    return CustomKeyboard;
}(React.Component);

CustomKeyboard.defaultProps = {
    prefixCls: 'am-number-keyboard',
    disabledKeys: null
};
export default CustomKeyboard;