var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
      r,
      ar = [],
      e;

  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) {
      ar.push(r.value);
    }
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }

  return ar;
};

import { useState, useCallback } from 'react';
import useUpdateEffect from '../useUpdateEffect';

function isFunction(obj) {
  return typeof obj === 'function';
}

export function createUseStorageState(nullishStorage) {
  function useStorageState(key, defaultValue) {
    var storage = nullishStorage;

    var _a = __read(useState(function () {
      return getStoredValue();
    }), 2),
        state = _a[0],
        setState = _a[1];

    useUpdateEffect(function () {
      setState(getStoredValue());
    }, [key]);

    function getStoredValue() {
      var raw = storage.getItem(key);

      if (raw) {
        try {
          return JSON.parse(raw);
        } catch (e) {}
      }

      if (isFunction(defaultValue)) {
        return defaultValue();
      }

      return defaultValue;
    }

    var updateState = useCallback(function (value) {
      if (typeof value === 'undefined') {
        storage.removeItem(key);
        setState(undefined);
      } else if (isFunction(value)) {
        var previousState = getStoredValue();
        var currentState = value(previousState);
        storage.setItem(key, JSON.stringify(currentState));
        setState(currentState);
      } else {
        storage.setItem(key, JSON.stringify(value));
        setState(value);
      }
    }, [key]);
    return [state, updateState];
  }

  if (!nullishStorage) {
    return function (_, defaultValue) {
      return [isFunction(defaultValue) ? defaultValue() : defaultValue, function () {}];
    };
  }

  return useStorageState;
}