"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  okText: '确定',
  cancelText: '取消',
  buttonText: '按钮'
};
exports["default"] = _default;