'use strict';

import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _createClass from 'babel-runtime/helpers/createClass';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
import React from 'react';
var DefaultDecorators = [{
    component: function (_React$Component) {
        _inherits(component, _React$Component);

        function component() {
            _classCallCheck(this, component);

            var _this = _possibleConstructorReturn(this, (component.__proto__ || Object.getPrototypeOf(component)).apply(this, arguments));

            _this.handleClick = function (e) {
                e.preventDefault();
                _this.props.previousSlide();
            };
            return _this;
        }

        _createClass(component, [{
            key: 'render',
            value: function render() {
                return React.createElement(
                    'button',
                    { style: this.getButtonStyles(this.props.currentSlide === 0 && !this.props.wrapAround), onClick: this.handleClick },
                    'PREV'
                );
            }
        }, {
            key: 'getButtonStyles',
            value: function getButtonStyles(disabled) {
                return {
                    border: 0,
                    background: 'rgba(0,0,0,0.4)',
                    color: 'white',
                    padding: 10,
                    outline: 0,
                    opacity: disabled ? 0.3 : 1,
                    cursor: 'pointer'
                };
            }
        }]);

        return component;
    }(React.Component),
    position: 'CenterLeft'
}, {
    component: function (_React$Component2) {
        _inherits(component, _React$Component2);

        function component() {
            _classCallCheck(this, component);

            var _this2 = _possibleConstructorReturn(this, (component.__proto__ || Object.getPrototypeOf(component)).apply(this, arguments));

            _this2.handleClick = function (e) {
                e.preventDefault();
                if (_this2.props.nextSlide) {
                    _this2.props.nextSlide();
                }
            };
            return _this2;
        }

        _createClass(component, [{
            key: 'render',
            value: function render() {
                return React.createElement(
                    'button',
                    { style: this.getButtonStyles(this.props.currentSlide + this.props.slidesToScroll >= this.props.slideCount && !this.props.wrapAround), onClick: this.handleClick },
                    'NEXT'
                );
            }
        }, {
            key: 'getButtonStyles',
            value: function getButtonStyles(disabled) {
                return {
                    border: 0,
                    background: 'rgba(0,0,0,0.4)',
                    color: 'white',
                    padding: 10,
                    outline: 0,
                    opacity: disabled ? 0.3 : 1,
                    cursor: 'pointer'
                };
            }
        }]);

        return component;
    }(React.Component),
    position: 'CenterRight'
}, {
    component: function (_React$Component3) {
        _inherits(component, _React$Component3);

        function component() {
            _classCallCheck(this, component);

            return _possibleConstructorReturn(this, (component.__proto__ || Object.getPrototypeOf(component)).apply(this, arguments));
        }

        _createClass(component, [{
            key: 'render',
            value: function render() {
                var _this4 = this;

                var indexes = this.getIndexes(this.props.slideCount, this.props.slidesToScroll);
                return React.createElement(
                    'ul',
                    { style: this.getListStyles() },
                    indexes.map(function (index) {
                        return React.createElement(
                            'li',
                            { style: _this4.getListItemStyles(), key: index },
                            React.createElement(
                                'button',
                                { style: _this4.getButtonStyles(_this4.props.currentSlide === index), onClick: _this4.props.goToSlide && _this4.props.goToSlide.bind(null, index) },
                                '\u2022'
                            )
                        );
                    })
                );
            }
        }, {
            key: 'getIndexes',
            value: function getIndexes(count, inc) {
                var arr = [];
                for (var i = 0; i < count; i += inc) {
                    arr.push(i);
                }
                return arr;
            }
        }, {
            key: 'getListStyles',
            value: function getListStyles() {
                return {
                    position: 'relative',
                    margin: 0,
                    top: -10,
                    padding: 0
                };
            }
        }, {
            key: 'getListItemStyles',
            value: function getListItemStyles() {
                return {
                    listStyleType: 'none',
                    display: 'inline-block'
                };
            }
        }, {
            key: 'getButtonStyles',
            value: function getButtonStyles(active) {
                return {
                    border: 0,
                    background: 'transparent',
                    color: 'black',
                    cursor: 'pointer',
                    padding: 10,
                    outline: 0,
                    fontSize: 24,
                    opacity: active ? 1 : 0.5
                };
            }
        }]);

        return component;
    }(React.Component),
    position: 'BottomCenter'
}];
export default DefaultDecorators;