import _extends from 'babel-runtime/helpers/extends';
import _createClass from 'babel-runtime/helpers/createClass';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import React from 'react';
export var StateType = function StateType() {
    _classCallCheck(this, StateType);
};
var instanceId = 0;
export var Tabs = function (_React$PureComponent) {
    _inherits(Tabs, _React$PureComponent);

    function Tabs(props) {
        _classCallCheck(this, Tabs);

        var _this = _possibleConstructorReturn(this, (Tabs.__proto__ || Object.getPrototypeOf(Tabs)).call(this, props));

        _this.tabCache = {};
        _this.isTabVertical = function () {
            var direction = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _this.props.tabDirection;
            return direction === 'vertical';
        };
        _this.shouldRenderTab = function (idx) {
            var _this$props$prerender = _this.props.prerenderingSiblingsNumber,
                prerenderingSiblingsNumber = _this$props$prerender === undefined ? 0 : _this$props$prerender;
            var _this$state$currentTa = _this.state.currentTab,
                currentTab = _this$state$currentTa === undefined ? 0 : _this$state$currentTa;

            return currentTab - prerenderingSiblingsNumber <= idx && idx <= currentTab + prerenderingSiblingsNumber;
        };
        _this.getOffsetIndex = function (current, width) {
            var threshold = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _this.props.distanceToChangeTab || 0;

            var ratio = Math.abs(current / width);
            var direction = ratio > _this.state.currentTab ? '<' : '>';
            var index = Math.floor(ratio);
            switch (direction) {
                case '<':
                    return ratio - index > threshold ? index + 1 : index;
                case '>':
                    return 1 - ratio + index > threshold ? index : index + 1;
                default:
                    return Math.round(ratio);
            }
        };
        _this.getSubElements = function () {
            var children = _this.props.children;

            var subElements = {};
            return function () {
                var defaultPrefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '$i$-';
                var allPrefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '$ALL$';

                if (Array.isArray(children)) {
                    children.forEach(function (child, index) {
                        if (child.key) {
                            subElements[child.key] = child;
                        }
                        subElements['' + defaultPrefix + index] = child;
                    });
                } else if (children) {
                    subElements[allPrefix] = children;
                }
                return subElements;
            };
        };
        _this.state = {
            currentTab: _this.getTabIndex(props)
        };
        _this.nextCurrentTab = _this.state.currentTab;
        _this.instanceId = instanceId++;
        return _this;
    }

    _createClass(Tabs, [{
        key: 'getTabIndex',
        value: function getTabIndex(props) {
            var page = props.page,
                initialPage = props.initialPage,
                tabs = props.tabs;

            var param = (page !== undefined ? page : initialPage) || 0;
            var index = 0;
            if (typeof param === 'string') {
                tabs.forEach(function (t, i) {
                    if (t.key === param) {
                        index = i;
                    }
                });
            } else {
                index = param || 0;
            }
            return index < 0 ? 0 : index;
        }
    }, {
        key: 'componentWillReceiveProps',
        value: function componentWillReceiveProps(nextProps) {
            if (this.props.page !== nextProps.page && nextProps.page !== undefined) {
                this.goToTab(this.getTabIndex(nextProps), true, {}, nextProps);
            }
        }
    }, {
        key: 'componentDidMount',
        value: function componentDidMount() {
            this.prevCurrentTab = this.state.currentTab;
        }
    }, {
        key: 'componentDidUpdate',
        value: function componentDidUpdate() {
            this.prevCurrentTab = this.state.currentTab;
        }
    }, {
        key: 'goToTab',
        value: function goToTab(index) {
            var force = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
            var newState = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
            var props = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : this.props;

            if (!force && this.nextCurrentTab === index) {
                return false;
            }
            this.nextCurrentTab = index;
            var tabs = props.tabs,
                onChange = props.onChange;

            if (index >= 0 && index < tabs.length) {
                if (!force) {
                    onChange && onChange(tabs[index], index);
                    if (props.page !== undefined) {
                        return false;
                    }
                }
                this.setState(_extends({ currentTab: index }, newState));
            }
            return true;
        }
    }, {
        key: 'tabClickGoToTab',
        value: function tabClickGoToTab(index) {
            this.goToTab(index);
        }
    }, {
        key: 'getTabBarBaseProps',
        value: function getTabBarBaseProps() {
            var currentTab = this.state.currentTab;
            var _props = this.props,
                animated = _props.animated,
                onTabClick = _props.onTabClick,
                tabBarActiveTextColor = _props.tabBarActiveTextColor,
                tabBarBackgroundColor = _props.tabBarBackgroundColor,
                tabBarInactiveTextColor = _props.tabBarInactiveTextColor,
                tabBarPosition = _props.tabBarPosition,
                tabBarTextStyle = _props.tabBarTextStyle,
                tabBarUnderlineStyle = _props.tabBarUnderlineStyle,
                tabs = _props.tabs;

            return {
                activeTab: currentTab,
                animated: !!animated,
                goToTab: this.tabClickGoToTab.bind(this),
                onTabClick: onTabClick,
                tabBarActiveTextColor: tabBarActiveTextColor,
                tabBarBackgroundColor: tabBarBackgroundColor,
                tabBarInactiveTextColor: tabBarInactiveTextColor,
                tabBarPosition: tabBarPosition,
                tabBarTextStyle: tabBarTextStyle,
                tabBarUnderlineStyle: tabBarUnderlineStyle,
                tabs: tabs,
                instanceId: this.instanceId
            };
        }
    }, {
        key: 'renderTabBar',
        value: function renderTabBar(tabBarProps, DefaultTabBar) {
            var renderTabBar = this.props.renderTabBar;

            if (renderTabBar === false) {
                return null;
            } else if (renderTabBar) {
                // return React.cloneElement(this.props.renderTabBar(props), props);
                return renderTabBar(tabBarProps);
            } else {
                return React.createElement(DefaultTabBar, tabBarProps);
            }
        }
    }, {
        key: 'getSubElement',
        value: function getSubElement(tab, index, subElements) {
            var defaultPrefix = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '$i$-';
            var allPrefix = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : '$ALL$';

            var key = tab.key || '' + defaultPrefix + index;
            var elements = subElements(defaultPrefix, allPrefix);
            var component = elements[key] || elements[allPrefix];
            if (component instanceof Function) {
                component = component(tab, index);
            }
            return component || null;
        }
    }]);

    return Tabs;
}(React.PureComponent);
Tabs.defaultProps = {
    tabBarPosition: 'top',
    initialPage: 0,
    swipeable: true,
    animated: true,
    prerenderingSiblingsNumber: 1,
    tabs: [],
    destroyInactiveTab: false,
    usePaged: true,
    tabDirection: 'horizontal',
    distanceToChangeTab: .3
};