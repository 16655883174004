"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _Flex = _interopRequireDefault(require("./Flex"));

var _FlexItem = _interopRequireDefault(require("./FlexItem"));

_Flex["default"].Item = _FlexItem["default"];
var _default = _Flex["default"];
exports["default"] = _default;