var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
      r,
      ar = [],
      e;

  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) {
      ar.push(r.value);
    }
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }

  return ar;
};

import { useEffect, useState } from 'react';
import usePersistFn from '../usePersistFn';
import { getTargetElement } from '../utils/dom';

function useScroll(target, shouldUpdate) {
  if (shouldUpdate === void 0) {
    shouldUpdate = function shouldUpdate() {
      return true;
    };
  }

  var _a = __read(useState({
    left: NaN,
    top: NaN
  }), 2),
      position = _a[0],
      setPosition = _a[1];

  var shouldUpdatePersist = usePersistFn(shouldUpdate);
  useEffect(function () {
    var el = getTargetElement(target, document);
    if (!el) return;

    function updatePosition(currentTarget) {
      var newPosition;

      if (currentTarget === document) {
        if (!document.scrollingElement) return;
        newPosition = {
          left: document.scrollingElement.scrollLeft,
          top: document.scrollingElement.scrollTop
        };
      } else {
        newPosition = {
          left: currentTarget.scrollLeft,
          top: currentTarget.scrollTop
        };
      }

      if (shouldUpdatePersist(newPosition)) setPosition(newPosition);
    }

    updatePosition(el);

    function listener(event) {
      if (!event.target) return;
      updatePosition(event.target);
    }

    el.addEventListener('scroll', listener);
    return function () {
      el.removeEventListener('scroll', listener);
    };
  }, [target, shouldUpdatePersist]);
  return position;
}

export default useScroll;