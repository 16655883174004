import { useEffect } from 'react';
import usePersistFn from '../usePersistFn';
import { isFunction } from '../utils';

var useUnmount = function useUnmount(fn) {
  var fnPersist = usePersistFn(fn);
  useEffect(function () {
    return function () {
      if (isFunction(fnPersist)) {
        fnPersist();
      }
    };
  }, []);
};

export default useUnmount;