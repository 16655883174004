'use strict';

import _extends from 'babel-runtime/helpers/extends';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _createClass from 'babel-runtime/helpers/createClass';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
import React from 'react';
import decorators from './decorators';
import ExecutionEnvironment from 'exenv';
import requestAnimationFrame from 'raf';
// from https://github.com/chenglou/tween-functions
function easeOutCirc(t, b, _c, d) {
    var c = _c - b;
    return c * Math.sqrt(1 - (t = t / d - 1) * t) + b;
}
function linear(t, b, _c, d) {
    var c = _c - b;
    return c * t / d + b;
}
var DEFAULT_STACK_BEHAVIOR = 'ADDITIVE';
var DEFAULT_DURATION = 300;
var DEFAULT_DELAY = 0;
var stackBehavior = {
    ADDITIVE: 'ADDITIVE',
    DESTRUCTIVE: 'DESTRUCTIVE'
};
var addEvent = function addEvent(elem, type, eventHandle) {
    if (elem === null || typeof elem === 'undefined') {
        return;
    }
    if (elem.addEventListener) {
        elem.addEventListener(type, eventHandle, false);
    } else if (elem.attachEvent) {
        elem.attachEvent('on' + type, eventHandle);
    } else {
        elem['on' + type] = eventHandle;
    }
};
var removeEvent = function removeEvent(elem, type, eventHandle) {
    if (elem === null || typeof elem === 'undefined') {
        return;
    }
    if (elem.removeEventListener) {
        elem.removeEventListener(type, eventHandle, false);
    } else if (elem.detachEvent) {
        elem.detachEvent('on' + type, eventHandle);
    } else {
        elem['on' + type] = null;
    }
};

var Carousel = function (_React$Component) {
    _inherits(Carousel, _React$Component);

    function Carousel(props) {
        _classCallCheck(this, Carousel);

        var _this = _possibleConstructorReturn(this, (Carousel.__proto__ || Object.getPrototypeOf(Carousel)).call(this, props));

        _this._rafCb = function () {
            var state = _this.state;
            if (state.tweenQueue.length === 0) {
                return;
            }
            var now = Date.now();
            var newTweenQueue = [];
            for (var i = 0; i < state.tweenQueue.length; i++) {
                var item = state.tweenQueue[i];
                var initTime = item.initTime,
                    config = item.config;

                if (now - initTime < config.duration) {
                    newTweenQueue.push(item);
                } else {
                    if (config.onEnd) {
                        config.onEnd();
                    }
                }
            }
            // onEnd might trigger a parent callback that removes this component
            // -1 means we've canceled it in componentWillUnmount
            if (_this._rafID === -1) {
                return;
            }
            _this.setState({
                tweenQueue: newTweenQueue
            });
            _this._rafID = requestAnimationFrame(_this._rafCb);
        };
        _this.handleClick = function (e) {
            if (_this.clickSafe === true) {
                e.preventDefault();
                e.stopPropagation();
                if (e.nativeEvent) {
                    e.nativeEvent.stopPropagation();
                }
            }
        };
        _this.autoplayIterator = function () {
            if (_this.props.wrapAround) {
                return _this.nextSlide();
            }
            if (_this.state.currentSlide !== _this.state.slideCount - _this.state.slidesToShow) {
                _this.nextSlide();
            } else {
                _this.stopAutoplay();
            }
        };
        // Action Methods
        _this.goToSlide = function (index) {
            var _this$props = _this.props,
                beforeSlide = _this$props.beforeSlide,
                afterSlide = _this$props.afterSlide;

            if (index >= React.Children.count(_this.props.children) || index < 0) {
                if (!_this.props.wrapAround) {
                    return;
                }
                ;
                if (index >= React.Children.count(_this.props.children)) {
                    beforeSlide(_this.state.currentSlide, 0);
                    return _this.setState({
                        currentSlide: 0
                    }, function () {
                        _this.animateSlide(null, null, _this.getTargetLeft(null, index), function () {
                            _this.animateSlide(null, 0.01);
                            afterSlide(0);
                            _this.resetAutoplay();
                            _this.setExternalData();
                        });
                    });
                } else {
                    var endSlide = React.Children.count(_this.props.children) - _this.state.slidesToScroll;
                    beforeSlide(_this.state.currentSlide, endSlide);
                    return _this.setState({
                        currentSlide: endSlide
                    }, function () {
                        _this.animateSlide(null, null, _this.getTargetLeft(null, index), function () {
                            _this.animateSlide(null, 0.01);
                            afterSlide(endSlide);
                            _this.resetAutoplay();
                            _this.setExternalData();
                        });
                    });
                }
            }
            beforeSlide(_this.state.currentSlide, index);
            _this.setState({
                currentSlide: index
            }, function () {
                _this.animateSlide();
                _this.props.afterSlide(index);
                _this.resetAutoplay();
                _this.setExternalData();
            });
        };
        _this.nextSlide = function () {
            var childrenCount = React.Children.count(_this.props.children);
            var slidesToShow = _this.props.slidesToShow;
            if (_this.props.slidesToScroll === 'auto') {
                slidesToShow = _this.state.slidesToScroll;
            }
            if (_this.state.currentSlide >= childrenCount - slidesToShow && !_this.props.wrapAround) {
                return;
            }
            if (_this.props.wrapAround) {
                _this.goToSlide(_this.state.currentSlide + _this.state.slidesToScroll);
            } else {
                if (_this.props.slideWidth !== 1) {
                    return _this.goToSlide(_this.state.currentSlide + _this.state.slidesToScroll);
                }
                _this.goToSlide(Math.min(_this.state.currentSlide + _this.state.slidesToScroll, childrenCount - slidesToShow));
            }
        };
        _this.previousSlide = function () {
            if (_this.state.currentSlide <= 0 && !_this.props.wrapAround) {
                return;
            }
            if (_this.props.wrapAround) {
                _this.goToSlide(_this.state.currentSlide - _this.state.slidesToScroll);
            } else {
                _this.goToSlide(Math.max(0, _this.state.currentSlide - _this.state.slidesToScroll));
            }
        };
        _this.onResize = function () {
            _this.setDimensions();
        };
        _this.onReadyStateChange = function () {
            _this.setDimensions();
        };
        _this.state = {
            currentSlide: _this.props.slideIndex,
            dragging: false,
            frameWidth: 0,
            left: 0,
            slideCount: 0,
            slidesToScroll: _this.props.slidesToScroll,
            slideWidth: 0,
            top: 0,
            tweenQueue: []
        };
        _this.touchObject = {};
        _this.clickSafe = true;
        return _this;
    }

    _createClass(Carousel, [{
        key: 'componentWillMount',
        value: function componentWillMount() {
            this.setInitialDimensions();
        }
    }, {
        key: 'componentDidMount',
        value: function componentDidMount() {
            this.setDimensions();
            this.bindEvents();
            this.setExternalData();
            if (this.props.autoplay) {
                this.startAutoplay();
            }
        }
    }, {
        key: 'componentWillReceiveProps',
        value: function componentWillReceiveProps(nextProps) {
            this.setState({
                slideCount: nextProps.children.length
            });
            this.setDimensions(nextProps);
            if (this.props.slideIndex !== nextProps.slideIndex && nextProps.slideIndex !== this.state.currentSlide) {
                this.goToSlide(nextProps.slideIndex);
            }
            if (this.props.autoplay !== nextProps.autoplay) {
                if (nextProps.autoplay) {
                    this.startAutoplay();
                } else {
                    this.stopAutoplay();
                }
            }
        }
    }, {
        key: 'componentWillUnmount',
        value: function componentWillUnmount() {
            this.unbindEvents();
            this.stopAutoplay();
            requestAnimationFrame.cancel(this._rafID);
            this._rafID = -1;
        }
        // react-tween-state

    }, {
        key: 'tweenState',
        value: function tweenState(path, _ref) {
            var _this2 = this;

            var easing = _ref.easing,
                duration = _ref.duration,
                delay = _ref.delay,
                beginValue = _ref.beginValue,
                endValue = _ref.endValue,
                onEnd = _ref.onEnd,
                configSB = _ref.stackBehavior;

            this.setState(function (state) {
                var cursor = state;
                var stateName = void 0;
                // see comment below on pash hash
                var pathHash = void 0;
                if (typeof path === 'string') {
                    stateName = path;
                    pathHash = path;
                } else {
                    for (var i = 0; i < path.length - 1; i++) {
                        cursor = cursor[path[i]];
                    }
                    stateName = path[path.length - 1];
                    pathHash = path.join('|');
                }
                // see the reasoning for these defaults at the top of file
                var newConfig = {
                    easing: easing,
                    duration: duration == null ? DEFAULT_DURATION : duration,
                    delay: delay == null ? DEFAULT_DELAY : delay,
                    beginValue: beginValue == null ? cursor[stateName] : beginValue,
                    endValue: endValue,
                    onEnd: onEnd,
                    stackBehavior: configSB || DEFAULT_STACK_BEHAVIOR
                };
                var newTweenQueue = state.tweenQueue;
                if (newConfig.stackBehavior === stackBehavior.DESTRUCTIVE) {
                    newTweenQueue = state.tweenQueue.filter(function (item) {
                        return item.pathHash !== pathHash;
                    });
                }
                // we store path hash, so that during value retrieval we can use hash
                // comparison to find the path. See the kind of shitty thing you have to
                // do when you don't have value comparison for collections?
                newTweenQueue.push({
                    pathHash: pathHash,
                    config: newConfig,
                    initTime: Date.now() + newConfig.delay
                });
                // sorry for mutating. For perf reasons we don't want to deep clone.
                // guys, can we please all start using persistent collections so that
                // we can stop worrying about nonesense like this
                cursor[stateName] = newConfig.endValue;
                if (newTweenQueue.length === 1) {
                    _this2._rafID = requestAnimationFrame(_this2._rafCb);
                }
                // this will also include the above mutated update
                return { tweenQueue: newTweenQueue };
            });
        }
    }, {
        key: 'getTweeningValue',
        value: function getTweeningValue(path) {
            var state = this.state;
            var tweeningValue = void 0;
            var pathHash = void 0;
            if (typeof path === 'string') {
                tweeningValue = state[path];
                pathHash = path;
            } else {
                tweeningValue = state;
                for (var i = 0; i < path.length; i++) {
                    tweeningValue = tweeningValue[path[i]];
                }
                pathHash = path.join('|');
            }
            var now = Date.now();
            for (var _i = 0; _i < state.tweenQueue.length; _i++) {
                var _state$tweenQueue$_i = state.tweenQueue[_i],
                    itemPathHash = _state$tweenQueue$_i.pathHash,
                    initTime = _state$tweenQueue$_i.initTime,
                    config = _state$tweenQueue$_i.config;

                if (itemPathHash !== pathHash) {
                    continue;
                }
                var progressTime = now - initTime > config.duration ? config.duration : Math.max(0, now - initTime);
                // `now - initTime` can be negative if initTime is scheduled in the
                // future by a delay. In this case we take 0
                // if duration is 0, consider that as jumping to endValue directly. This
                // is needed because the easing functino might have undefined behavior for
                // duration = 0
                var easeValue = config.duration === 0 ? config.endValue : config.easing(progressTime, config.beginValue, config.endValue, config.duration);
                var contrib = easeValue - config.endValue;
                tweeningValue += contrib;
            }
            return tweeningValue;
        }
    }, {
        key: 'render',
        value: function render() {
            var _this3 = this;

            var children = React.Children.count(this.props.children) > 1 ? this.formatChildren(this.props.children) : this.props.children;
            return React.createElement(
                'div',
                { className: ['slider', this.props.className || ''].join(' '), ref: 'slider', style: _extends({}, this.getSliderStyles(), this.props.style) },
                React.createElement(
                    'div',
                    _extends({ className: 'slider-frame', ref: 'frame', style: this.getFrameStyles() }, this.getTouchEvents(), this.getMouseEvents(), { onClick: this.handleClick }),
                    React.createElement(
                        'ul',
                        { className: 'slider-list', ref: 'list', style: this.getListStyles() },
                        children
                    )
                ),
                this.props.decorators ? this.props.decorators.map(function (Decorator, index) {
                    return React.createElement(
                        'div',
                        { style: _extends({}, _this3.getDecoratorStyles(Decorator.position), Decorator.style || {}), className: 'slider-decorator-' + index, key: index },
                        React.createElement(Decorator.component, { currentSlide: _this3.state.currentSlide, slideCount: _this3.state.slideCount, frameWidth: _this3.state.frameWidth, slideWidth: _this3.state.slideWidth, slidesToScroll: _this3.state.slidesToScroll, cellSpacing: _this3.props.cellSpacing, slidesToShow: _this3.props.slidesToShow, wrapAround: _this3.props.wrapAround, nextSlide: _this3.nextSlide, previousSlide: _this3.previousSlide, goToSlide: _this3.goToSlide })
                    );
                }) : null,
                React.createElement('style', { type: 'text/css', dangerouslySetInnerHTML: { __html: this.getStyleTagStyles() } })
            );
        }
        // Touch Events

    }, {
        key: 'getTouchEvents',
        value: function getTouchEvents() {
            var self = this;
            if (this.props.swiping === false) {
                return null;
            }
            return {
                onTouchStart: function onTouchStart(e) {
                    self.touchObject = {
                        startX: e.touches[0].pageX,
                        startY: e.touches[0].pageY
                    };
                    self.handleMouseOver();
                },
                onTouchMove: function onTouchMove(e) {
                    var direction = self.swipeDirection(self.touchObject.startX, e.touches[0].pageX, self.touchObject.startY, e.touches[0].pageY);
                    if (direction !== 0) {
                        e.preventDefault();
                    }
                    var length = self.props.vertical ? Math.round(Math.sqrt(Math.pow(e.touches[0].pageY - self.touchObject.startY, 2))) : Math.round(Math.sqrt(Math.pow(e.touches[0].pageX - self.touchObject.startX, 2)));
                    self.touchObject = {
                        startX: self.touchObject.startX,
                        startY: self.touchObject.startY,
                        endX: e.touches[0].pageX,
                        endY: e.touches[0].pageY,
                        length: length,
                        direction: direction
                    };
                    self.setState({
                        left: self.props.vertical ? 0 : self.getTargetLeft(self.touchObject.length * self.touchObject.direction),
                        top: self.props.vertical ? self.getTargetLeft(self.touchObject.length * self.touchObject.direction) : 0
                    });
                },
                onTouchEnd: function onTouchEnd(e) {
                    self.handleSwipe(e);
                    self.handleMouseOut();
                },
                onTouchCancel: function onTouchCancel(e) {
                    self.handleSwipe(e);
                }
            };
        }
    }, {
        key: 'getMouseEvents',
        value: function getMouseEvents() {
            var self = this;
            if (this.props.dragging === false) {
                return null;
            }
            return {
                onMouseOver: function onMouseOver() {
                    self.handleMouseOver();
                },
                onMouseOut: function onMouseOut() {
                    self.handleMouseOut();
                },
                onMouseDown: function onMouseDown(e) {
                    self.touchObject = {
                        startX: e.clientX,
                        startY: e.clientY
                    };
                    self.setState({
                        dragging: true
                    });
                },
                onMouseMove: function onMouseMove(e) {
                    if (!self.state.dragging) {
                        return;
                    }
                    var direction = self.swipeDirection(self.touchObject.startX, e.clientX, self.touchObject.startY, e.clientY);
                    if (direction !== 0) {
                        e.preventDefault();
                    }
                    var length = self.props.vertical ? Math.round(Math.sqrt(Math.pow(e.clientY - self.touchObject.startY, 2))) : Math.round(Math.sqrt(Math.pow(e.clientX - self.touchObject.startX, 2)));
                    self.touchObject = {
                        startX: self.touchObject.startX,
                        startY: self.touchObject.startY,
                        endX: e.clientX,
                        endY: e.clientY,
                        length: length,
                        direction: direction
                    };
                    self.setState({
                        left: self.props.vertical ? 0 : self.getTargetLeft(self.touchObject.length * self.touchObject.direction),
                        top: self.props.vertical ? self.getTargetLeft(self.touchObject.length * self.touchObject.direction) : 0
                    });
                },
                onMouseUp: function onMouseUp(e) {
                    if (!self.state.dragging) {
                        return;
                    }
                    self.handleSwipe(e);
                },
                onMouseLeave: function onMouseLeave(e) {
                    if (!self.state.dragging) {
                        return;
                    }
                    self.handleSwipe(e);
                }
            };
        }
    }, {
        key: 'handleMouseOver',
        value: function handleMouseOver() {
            if (this.props.autoplay) {
                this.autoplayPaused = true;
                this.stopAutoplay();
            }
        }
    }, {
        key: 'handleMouseOut',
        value: function handleMouseOut() {
            if (this.props.autoplay && this.autoplayPaused) {
                this.startAutoplay();
                this.autoplayPaused = null;
            }
        }
    }, {
        key: 'handleSwipe',
        value: function handleSwipe(_) {
            if (typeof this.touchObject.length !== 'undefined' && this.touchObject.length > 44) {
                this.clickSafe = true;
            } else {
                this.clickSafe = false;
            }
            var _props = this.props,
                slidesToShow = _props.slidesToShow,
                slidesToScroll = _props.slidesToScroll,
                swipeSpeed = _props.swipeSpeed;
            // var slidesToShow = this.props.slidesToShow;

            if (slidesToScroll === 'auto') {
                slidesToShow = this.state.slidesToScroll;
            }
            if (React.Children.count(this.props.children) > 1 && this.touchObject.length > this.state.slideWidth / slidesToShow / swipeSpeed) {
                if (this.touchObject.direction === 1) {
                    if (this.state.currentSlide >= React.Children.count(this.props.children) - slidesToShow && !this.props.wrapAround) {
                        this.animateSlide(this.props.edgeEasing);
                    } else {
                        this.nextSlide();
                    }
                } else if (this.touchObject.direction === -1) {
                    if (this.state.currentSlide <= 0 && !this.props.wrapAround) {
                        this.animateSlide(this.props.edgeEasing);
                    } else {
                        this.previousSlide();
                    }
                }
            } else {
                this.goToSlide(this.state.currentSlide);
            }
            this.touchObject = {};
            this.setState({
                dragging: false
            });
        }
    }, {
        key: 'swipeDirection',
        value: function swipeDirection(x1, x2, y1, y2) {
            var xDist = x1 - x2;
            var yDist = y1 - y2;
            var r = Math.atan2(yDist, xDist);
            var swipeAngle = Math.round(r * 180 / Math.PI);
            if (swipeAngle < 0) {
                swipeAngle = 360 - Math.abs(swipeAngle);
            }
            if (swipeAngle <= 45 && swipeAngle >= 0) {
                return 1;
            }
            if (swipeAngle <= 360 && swipeAngle >= 315) {
                return 1;
            }
            if (swipeAngle >= 135 && swipeAngle <= 225) {
                return -1;
            }
            if (this.props.vertical === true) {
                if (swipeAngle >= 35 && swipeAngle <= 135) {
                    return 1;
                } else {
                    return -1;
                }
            }
            return 0;
        }
    }, {
        key: 'startAutoplay',
        value: function startAutoplay() {
            if (React.Children.count(this.props.children) <= 1) {
                return;
            }
            this.autoplayID = setInterval(this.autoplayIterator, this.props.autoplayInterval);
        }
    }, {
        key: 'resetAutoplay',
        value: function resetAutoplay() {
            if (this.props.resetAutoplay && this.props.autoplay && !this.autoplayPaused) {
                this.stopAutoplay();
                this.startAutoplay();
            }
        }
    }, {
        key: 'stopAutoplay',
        value: function stopAutoplay() {
            if (this.autoplayID) {
                clearInterval(this.autoplayID);
            }
        }
        // Animation

    }, {
        key: 'animateSlide',
        value: function animateSlide(easing, duration, endValue, callback) {
            this.tweenState(this.props.vertical ? 'top' : 'left', {
                easing: easing || this.props.easing,
                duration: duration || this.props.speed,
                endValue: endValue || this.getTargetLeft(),
                delay: null,
                beginValue: null,
                onEnd: callback || null,
                stackBehavior: stackBehavior
            });
        }
    }, {
        key: 'getTargetLeft',
        value: function getTargetLeft(touchOffset, slide) {
            var offset = void 0;
            var target = slide || this.state.currentSlide;
            var cellSpacing = this.props.cellSpacing;
            switch (this.props.cellAlign) {
                case 'left':
                    {
                        offset = 0;
                        offset -= cellSpacing * target;
                        break;
                    }
                case 'center':
                    {
                        offset = (this.state.frameWidth - this.state.slideWidth) / 2;
                        offset -= cellSpacing * target;
                        break;
                    }
                case 'right':
                    {
                        offset = this.state.frameWidth - this.state.slideWidth;
                        offset -= cellSpacing * target;
                        break;
                    }
                default:
                    break;
            }
            var left = this.state.slideWidth * target;
            var lastSlide = this.state.currentSlide > 0 && target + this.state.slidesToScroll >= this.state.slideCount;
            if (lastSlide && this.props.slideWidth !== 1 && !this.props.wrapAround && this.props.slidesToScroll === 'auto') {
                left = this.state.slideWidth * this.state.slideCount - this.state.frameWidth;
                offset = 0;
                offset -= cellSpacing * (this.state.slideCount - 1);
            }
            offset -= touchOffset || 0;
            return (left - offset) * -1;
        }
        // Bootstrapping

    }, {
        key: 'bindEvents',
        value: function bindEvents() {
            if (ExecutionEnvironment.canUseDOM) {
                addEvent(window, 'resize', this.onResize);
                addEvent(document, 'readystatechange', this.onReadyStateChange);
            }
        }
    }, {
        key: 'unbindEvents',
        value: function unbindEvents() {
            if (ExecutionEnvironment.canUseDOM) {
                removeEvent(window, 'resize', this.onResize);
                removeEvent(document, 'readystatechange', this.onReadyStateChange);
            }
        }
    }, {
        key: 'formatChildren',
        value: function formatChildren(children) {
            var _this4 = this;

            var positionValue = this.props.vertical ? this.getTweeningValue('top') : this.getTweeningValue('left');
            return React.Children.map(children, function (child, index) {
                return React.createElement(
                    'li',
                    { className: 'slider-slide', style: _this4.getSlideStyles(index, positionValue), key: index },
                    child
                );
            });
        }
    }, {
        key: 'setInitialDimensions',
        value: function setInitialDimensions() {
            var _this5 = this;

            var _props2 = this.props,
                vertical = _props2.vertical,
                initialSlideHeight = _props2.initialSlideHeight,
                initialSlideWidth = _props2.initialSlideWidth,
                slidesToShow = _props2.slidesToShow,
                cellSpacing = _props2.cellSpacing,
                children = _props2.children;

            var slideWidth = vertical ? initialSlideHeight || 0 : initialSlideWidth || 0;
            var slideHeight = initialSlideHeight ? initialSlideHeight * slidesToShow : 0;
            var frameHeight = slideHeight + cellSpacing * (slidesToShow - 1);
            this.setState({
                slideHeight: slideHeight,
                frameWidth: vertical ? frameHeight : '100%',
                slideCount: React.Children.count(children),
                slideWidth: slideWidth
            }, function () {
                _this5.setLeft();
                _this5.setExternalData();
            });
        }
    }, {
        key: 'setDimensions',
        value: function setDimensions(props) {
            var _this6 = this;

            props = props || this.props;
            var frameWidth = void 0;
            var frameHeight = void 0;
            var slideHeight = void 0;
            var slideWidth = void 0;
            var slidesToScroll = props.slidesToScroll;
            var frame = this.refs.frame;
            var firstSlide = frame.childNodes[0].childNodes[0];
            if (firstSlide) {
                firstSlide.style.height = 'auto';
                slideHeight = this.props.vertical ? firstSlide.offsetHeight * props.slidesToShow : firstSlide.offsetHeight;
            } else {
                slideHeight = 100;
            }
            if (typeof props.slideWidth !== 'number') {
                slideWidth = parseInt(props.slideWidth, 10);
            } else {
                if (props.vertical) {
                    slideWidth = slideHeight / props.slidesToShow * props.slideWidth;
                } else {
                    slideWidth = frame.offsetWidth / props.slidesToShow * props.slideWidth;
                }
            }
            if (!props.vertical) {
                slideWidth -= props.cellSpacing * ((100 - 100 / props.slidesToShow) / 100);
            }
            frameHeight = slideHeight + props.cellSpacing * (props.slidesToShow - 1);
            frameWidth = props.vertical ? frameHeight : frame.offsetWidth;
            if (props.slidesToScroll === 'auto') {
                slidesToScroll = Math.floor(frameWidth / (slideWidth + props.cellSpacing));
            }
            this.setState({
                slideHeight: slideHeight,
                frameWidth: frameWidth,
                slideWidth: slideWidth,
                slidesToScroll: slidesToScroll,
                left: props.vertical ? 0 : this.getTargetLeft(),
                top: props.vertical ? this.getTargetLeft() : 0
            }, function () {
                _this6.setLeft();
            });
        }
    }, {
        key: 'setLeft',
        value: function setLeft() {
            this.setState({
                left: this.props.vertical ? 0 : this.getTargetLeft(),
                top: this.props.vertical ? this.getTargetLeft() : 0
            });
        }
        // Data

    }, {
        key: 'setExternalData',
        value: function setExternalData() {
            if (this.props.data) {
                this.props.data();
            }
        }
        // Styles

    }, {
        key: 'getListStyles',
        value: function getListStyles() {
            var listWidth = this.state.slideWidth * React.Children.count(this.props.children);
            var cellSpacing = this.props.cellSpacing;
            var spacingOffset = cellSpacing * React.Children.count(this.props.children);
            var transform = 'translate3d(' + this.getTweeningValue('left') + 'px, ' + this.getTweeningValue('top') + 'px, 0)';
            return {
                transform: transform,
                WebkitTransform: transform,
                msTransform: 'translate(' + this.getTweeningValue('left') + 'px, ' + this.getTweeningValue('top') + 'px)',
                position: 'relative',
                display: 'block',
                margin: this.props.vertical ? cellSpacing / 2 * -1 + 'px 0px' : '0px ' + cellSpacing / 2 * -1 + 'px',
                padding: 0,
                height: this.props.vertical ? listWidth + spacingOffset : this.state.slideHeight,
                width: this.props.vertical ? 'auto' : listWidth + spacingOffset,
                cursor: this.state.dragging === true ? 'pointer' : 'inherit',
                boxSizing: 'border-box',
                MozBoxSizing: 'border-box'
            };
        }
    }, {
        key: 'getFrameStyles',
        value: function getFrameStyles() {
            return {
                position: 'relative',
                display: 'block',
                overflow: this.props.frameOverflow,
                height: this.props.vertical ? this.state.frameWidth || 'initial' : 'auto',
                margin: this.props.framePadding,
                padding: 0,
                transform: 'translate3d(0, 0, 0)',
                WebkitTransform: 'translate3d(0, 0, 0)',
                msTransform: 'translate(0, 0)',
                boxSizing: 'border-box',
                MozBoxSizing: 'border-box'
            };
        }
    }, {
        key: 'getSlideStyles',
        value: function getSlideStyles(index, positionValue) {
            var targetPosition = this.getSlideTargetPosition(index, positionValue);
            var cellSpacing = this.props.cellSpacing;
            return {
                position: 'absolute',
                left: this.props.vertical ? 0 : targetPosition,
                top: this.props.vertical ? targetPosition : 0,
                display: this.props.vertical ? 'block' : 'inline-block',
                listStyleType: 'none',
                verticalAlign: 'top',
                width: this.props.vertical ? '100%' : this.state.slideWidth,
                height: 'auto',
                boxSizing: 'border-box',
                MozBoxSizing: 'border-box',
                marginLeft: this.props.vertical ? 'auto' : cellSpacing / 2,
                marginRight: this.props.vertical ? 'auto' : cellSpacing / 2,
                marginTop: this.props.vertical ? cellSpacing / 2 : 'auto',
                marginBottom: this.props.vertical ? cellSpacing / 2 : 'auto'
            };
        }
    }, {
        key: 'getSlideTargetPosition',
        value: function getSlideTargetPosition(index, positionValue) {
            var slidesToShow = this.state.frameWidth / this.state.slideWidth;
            var targetPosition = (this.state.slideWidth + this.props.cellSpacing) * index;
            var end = (this.state.slideWidth + this.props.cellSpacing) * slidesToShow * -1;
            if (this.props.wrapAround) {
                var slidesBefore = Math.ceil(positionValue / this.state.slideWidth);
                if (this.state.slideCount - slidesBefore <= index) {
                    return (this.state.slideWidth + this.props.cellSpacing) * (this.state.slideCount - index) * -1;
                }
                var slidesAfter = Math.ceil((Math.abs(positionValue) - Math.abs(end)) / this.state.slideWidth);
                if (this.state.slideWidth !== 1) {
                    slidesAfter = Math.ceil((Math.abs(positionValue) - this.state.slideWidth) / this.state.slideWidth);
                }
                if (index <= slidesAfter - 1) {
                    return (this.state.slideWidth + this.props.cellSpacing) * (this.state.slideCount + index);
                }
            }
            return targetPosition;
        }
    }, {
        key: 'getSliderStyles',
        value: function getSliderStyles() {
            return {
                position: 'relative',
                display: 'block',
                width: this.props.width,
                height: 'auto',
                boxSizing: 'border-box',
                MozBoxSizing: 'border-box',
                visibility: this.state.slideWidth ? 'visible' : 'hidden'
            };
        }
    }, {
        key: 'getStyleTagStyles',
        value: function getStyleTagStyles() {
            return '.slider-slide > img {width: 100%; display: block;}';
        }
    }, {
        key: 'getDecoratorStyles',
        value: function getDecoratorStyles(position) {
            switch (position) {
                case 'TopLeft':
                    {
                        return {
                            position: 'absolute',
                            top: 0,
                            left: 0
                        };
                    }
                case 'TopCenter':
                    {
                        return {
                            position: 'absolute',
                            top: 0,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            WebkitTransform: 'translateX(-50%)',
                            msTransform: 'translateX(-50%)'
                        };
                    }
                case 'TopRight':
                    {
                        return {
                            position: 'absolute',
                            top: 0,
                            right: 0
                        };
                    }
                case 'CenterLeft':
                    {
                        return {
                            position: 'absolute',
                            top: '50%',
                            left: 0,
                            transform: 'translateY(-50%)',
                            WebkitTransform: 'translateY(-50%)',
                            msTransform: 'translateY(-50%)'
                        };
                    }
                case 'CenterCenter':
                    {
                        return {
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%,-50%)',
                            WebkitTransform: 'translate(-50%, -50%)',
                            msTransform: 'translate(-50%, -50%)'
                        };
                    }
                case 'CenterRight':
                    {
                        return {
                            position: 'absolute',
                            top: '50%',
                            right: 0,
                            transform: 'translateY(-50%)',
                            WebkitTransform: 'translateY(-50%)',
                            msTransform: 'translateY(-50%)'
                        };
                    }
                case 'BottomLeft':
                    {
                        return {
                            position: 'absolute',
                            bottom: 0,
                            left: 0
                        };
                    }
                case 'BottomCenter':
                    {
                        return {
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            textAlign: 'center'
                        };
                    }
                case 'BottomRight':
                    {
                        return {
                            position: 'absolute',
                            bottom: 0,
                            right: 0
                        };
                    }
                default:
                    {
                        return {
                            position: 'absolute',
                            top: 0,
                            left: 0
                        };
                    }
            }
        }
    }]);

    return Carousel;
}(React.Component);

Carousel.defaultProps = {
    afterSlide: function afterSlide() {},
    autoplay: false,
    resetAutoplay: true,
    swipeSpeed: 12,
    autoplayInterval: 3000,
    beforeSlide: function beforeSlide() {},
    cellAlign: 'left',
    cellSpacing: 0,
    data: function data() {},
    decorators: decorators,
    dragging: true,
    easing: easeOutCirc,
    edgeEasing: linear,
    framePadding: '0px',
    frameOverflow: 'hidden',
    slideIndex: 0,
    slidesToScroll: 1,
    slidesToShow: 1,
    slideWidth: 1,
    speed: 500,
    swiping: true,
    vertical: false,
    width: '100%',
    wrapAround: false,
    style: {}
};
export default Carousel;