import _extends from 'babel-runtime/helpers/extends';
import _defineProperty from 'babel-runtime/helpers/defineProperty';
import _createClass from 'babel-runtime/helpers/createClass';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
var __rest = this && this.__rest || function (s, e) {
    var t = {};
    for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    }if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
        if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
    }return t;
};
import classnames from 'classnames';
import * as React from 'react';
import Dialog from 'rmc-dialog';
import TouchFeedback from 'rmc-feedback';
export var ModalComponent = function (_React$Component) {
    _inherits(ModalComponent, _React$Component);

    function ModalComponent() {
        _classCallCheck(this, ModalComponent);

        return _possibleConstructorReturn(this, (ModalComponent.__proto__ || Object.getPrototypeOf(ModalComponent)).apply(this, arguments));
    }

    return ModalComponent;
}(React.Component);

var Modal = function (_ModalComponent) {
    _inherits(Modal, _ModalComponent);

    function Modal() {
        _classCallCheck(this, Modal);

        return _possibleConstructorReturn(this, (Modal.__proto__ || Object.getPrototypeOf(Modal)).apply(this, arguments));
    }

    _createClass(Modal, [{
        key: 'renderFooterButton',
        value: function renderFooterButton(button, prefixCls, i) {
            var buttonStyle = {};
            if (button.style) {
                buttonStyle = button.style;
                if (typeof buttonStyle === 'string') {
                    var styleMap = {
                        cancel: {},
                        'default': {},
                        destructive: { color: 'red' }
                    };
                    buttonStyle = styleMap[buttonStyle] || {};
                }
            }
            var onClickFn = function onClickFn(e) {
                e.preventDefault();
                if (button.onPress) {
                    button.onPress();
                }
            };
            return React.createElement(
                TouchFeedback,
                { activeClassName: prefixCls + '-button-active', key: i },
                React.createElement(
                    'a',
                    { className: prefixCls + '-button', role: 'button', style: buttonStyle, onClick: onClickFn },
                    button.text || 'Button'
                )
            );
        }
    }, {
        key: 'render',
        value: function render() {
            var _this3 = this,
                _classnames2;

            var _a = this.props,
                prefixCls = _a.prefixCls,
                className = _a.className,
                wrapClassName = _a.wrapClassName,
                transitionName = _a.transitionName,
                maskTransitionName = _a.maskTransitionName,
                style = _a.style,
                platform = _a.platform,
                _a$footer = _a.footer,
                footer = _a$footer === undefined ? [] : _a$footer,
                operation = _a.operation,
                animated = _a.animated,
                transparent = _a.transparent,
                popup = _a.popup,
                animationType = _a.animationType,
                restProps = __rest(_a, ["prefixCls", "className", "wrapClassName", "transitionName", "maskTransitionName", "style", "platform", "footer", "operation", "animated", "transparent", "popup", "animationType"]);
            var btnGroupClass = classnames(prefixCls + '-button-group-' + (footer.length === 2 && !operation ? 'h' : 'v'), prefixCls + '-button-group-' + (operation ? 'operation' : 'normal'));
            var footerDom = footer.length ? React.createElement(
                'div',
                { className: btnGroupClass, role: 'group' },
                footer.map(function (button, i) {
                    return (
                        // tslint:disable-next-line:jsx-no-multiline-js
                        _this3.renderFooterButton(button, prefixCls, i)
                    );
                })
            ) : null;
            var transName = void 0;
            var maskTransName = void 0;
            if (animated) {
                // tslint:disable-next-line:prefer-conditional-expression
                if (transparent) {
                    transName = maskTransName = 'am-fade';
                } else {
                    transName = maskTransName = 'am-slide-up';
                }
                if (popup) {
                    transName = animationType === 'slide-up' ? 'am-slide-up' : 'am-slide-down';
                    maskTransName = 'am-fade';
                }
            }
            var wrapCls = classnames(wrapClassName, _defineProperty({}, prefixCls + '-wrap-popup', popup));
            var cls = classnames(className, (_classnames2 = {}, _defineProperty(_classnames2, prefixCls + '-transparent', transparent), _defineProperty(_classnames2, prefixCls + '-popup', popup), _defineProperty(_classnames2, prefixCls + '-popup-' + animationType, popup && animationType), _defineProperty(_classnames2, prefixCls + '-android', platform === 'android'), _classnames2));
            return React.createElement(Dialog, _extends({}, restProps, { prefixCls: prefixCls, className: cls, wrapClassName: wrapCls, transitionName: transitionName || transName, maskTransitionName: maskTransitionName || maskTransName, style: style, footer: footerDom }));
        }
    }]);

    return Modal;
}(ModalComponent);

export default Modal;

Modal.defaultProps = {
    prefixCls: 'am-modal',
    transparent: false,
    popup: false,
    animationType: 'slide-down',
    animated: true,
    style: {},
    onShow: function onShow() {},

    footer: [],
    closable: false,
    operation: false,
    platform: 'ios'
};