import _extends from 'babel-runtime/helpers/extends';
import _defineProperty from 'babel-runtime/helpers/defineProperty';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _createClass from 'babel-runtime/helpers/createClass';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
var __rest = this && this.__rest || function (s, e) {
    var t = {};
    for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    }if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
        if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
    }return t;
};
import classnames from 'classnames';
import * as React from 'react';
import List from '../list';
import Checkbox from './Checkbox';
var ListItem = List.Item;
// tslint:disable-next-line:no-empty
function noop() {}

var CheckboxItem = function (_React$Component) {
    _inherits(CheckboxItem, _React$Component);

    function CheckboxItem() {
        _classCallCheck(this, CheckboxItem);

        return _possibleConstructorReturn(this, (CheckboxItem.__proto__ || Object.getPrototypeOf(CheckboxItem)).apply(this, arguments));
    }

    _createClass(CheckboxItem, [{
        key: 'render',
        value: function render() {
            var _this2 = this;

            var _a = this.props,
                listPrefixCls = _a.listPrefixCls,
                onChange = _a.onChange,
                disabled = _a.disabled,
                checkboxProps = _a.checkboxProps,
                onClick = _a.onClick,
                restProps = __rest(_a, ["listPrefixCls", "onChange", "disabled", "checkboxProps", "onClick"]);var prefixCls = restProps.prefixCls,
                className = restProps.className,
                children = restProps.children;

            var wrapCls = classnames(prefixCls + '-item', className, _defineProperty({}, prefixCls + '-item-disabled', disabled === true));
            // Note: if not omit `onChange`, it will trigger twice on check listitem
            if (!disabled) {
                restProps.onClick = onClick || noop;
            }
            var extraProps = {};
            ['name', 'defaultChecked', 'checked', 'onChange', 'disabled'].forEach(function (i) {
                if (i in _this2.props) {
                    extraProps[i] = _this2.props[i];
                }
            });
            return React.createElement(
                ListItem,
                _extends({}, restProps, { prefixCls: listPrefixCls, className: wrapCls, thumb: React.createElement(Checkbox, _extends({}, checkboxProps, extraProps)) }),
                children
            );
        }
    }]);

    return CheckboxItem;
}(React.Component);

export default CheckboxItem;

CheckboxItem.defaultProps = {
    prefixCls: 'am-checkbox',
    listPrefixCls: 'am-list',
    checkboxProps: {}
};