"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _Modal = _interopRequireDefault(require("./Modal"));

var _alert = _interopRequireDefault(require("./alert"));

var _prompt = _interopRequireDefault(require("./prompt"));

var _operation = _interopRequireDefault(require("./operation"));

_Modal["default"].alert = _alert["default"];
_Modal["default"].prompt = _prompt["default"];
_Modal["default"].operation = _operation["default"];
var _default = _Modal["default"];
exports["default"] = _default;